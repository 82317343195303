.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.column {
  background-color: #388E3C;
  flex-shrink: 0;
  flex-grow: 1;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.rootQmsClass {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

