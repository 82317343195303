.ModalContentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  margin-right: 20%;
  height: 335px;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 2px 2px rgb(20, 48, 19);
}

.ClearAllOrdersModalTitleBackground,
.DetailsContainerTop {
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
}

.TopRightIcon {
  float: right;
  margin-right: -5%;
  margin-top: -5%;

}

.ClearAllOrdersTextContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 34px;
}

.ClearAllOrdersErrorTextContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 34px;
}

.ClearAllOrdersText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.1125px;

  color: #282828;
}

.ClearAllOrdersErrorText {
  font-style: oblique;
  color: #ff0000;
}

.ClearAllOrdersHeaderText {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.PointerIcon:hover {
  cursor: pointer;
}

.ClearAllOrdersModalBorder {
  border-left: 4px #D14944 solid;
}

.ClearAllOrdersModalTitleBackground {
  background-color: #D14944;
}

.ButtonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-left: 10%;
  padding: 6px 24px 34px;
}

.Button {
  font-family: 'Roboto', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.13125px;
  text-transform: uppercase;
}

.CloseModalButton {
  color: #DA291C;
}

.CancelOrderButton {
  color: #FFFFFF;
}
