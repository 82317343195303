
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
  height: 0;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
