.logo {
  margin-left: 33%;
  margin-right: 33%;
}

.form {
  text-align: center;
  margin-left: 33%;
  margin-right: 33%;
}

.button {
  text-align: center;
  margin: 2% 33%;
}
