.Pointer:hover {
  cursor: pointer;
}

.SummaryValue {
  font-weight: bold;
  font-size: 20px;  
}

.ReportRange {
  background: '#fff';
  cursor: pointer;
  padding: '5px 10px';
  border: '1px solid #ccc';
  width: '100%';
}
