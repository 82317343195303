
.QueueLabelContainer {
  justify-content: flex-end;
  align-self: flex-end;
  padding-bottom: 12px;
  padding-right: 25px;

}

.PointerIcon:hover {
  cursor: pointer;
}

.DisabledButton {
  opacity: 50%;
}
