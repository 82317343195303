.DetailsContainer {
  margin-left: 20%;
  margin-right: 20%;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 2px 2px rgb(20, 48, 19);
}

.BackgroundNew,
.BackgroundInProgress,
.BackgroundReady,
.BackgroundPickedUp,
.BackgroundUnknown,
.DetailsContainerTop {
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
}

.DetailsContainerIcon {
  float: right;
  margin-right: -5%;
  margin-top: -5%;

}

.OrderHeader {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}

.OrderHeaderText {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.OrderInformation {
  color: dimgrey;
  margin-bottom: 1px;
  margin-top: 4%;
}

.OrderNumber {
  font-weight: bold;
  font-size: 26px;
}

.OrderTime {
  font-size: 20px;
}

.DetailsList {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}

.Summary {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
}

.SegmentTitleParagraph {
  margin-bottom: 1px;
  color: dimgrey;
}

.SegmentContentParagraph {
  font-size: 19px;
  font-weight: bold;
}

.PointerIcon:hover {
  cursor: pointer;
}

.QueueLabel {
  font-size: 72px;
}

.DetailsList {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.DetailsListItem {
  font-size: 16px;
  font-weight: bold;
  overflow-wrap: break-word;
}

.DetailsListItemDescription {
  font-size: 12px;
  font-weight: normal;
}

.DetailsListSubItemDescription{
  font-size: 12px;
  font-weight: bolder;
}

.DetailsListItemDescriptionStriked {
  font-size: 12px;
  font-weight: normal;
  text-decoration: line-through;
}

.BorderNew {
  border-left: 4px #D14944 solid;
}

.BorderInProgress {
  border-left: 4px #E1972E solid;
}

.BorderReady {
  border-left: 4px #23AD96 solid;
}

.BorderPickedUp {
  border-left: 4px #31dcff solid;
}

.BorderUnknown {
  border-left: 4px #e5daef solid;
}

.BackgroundNew {
  background-color: #D14944;
}

.BackgroundInProgress {
  background-color: #E1972E;
}

.BackgroundReady {
  background-color: #23AD96;
}

.BackgroundPickedUp {
  background-color: #31dcff;
}

.BackgroundUnknown {
  background-color: #e5daef;
}

.AllergyNotes {
  background: #FFC9C9;
  border-top: 1px #E0E0E0 solid;
  border-bottom: 1px #E0E0E0 solid;
  margin-top: 2%;
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
}

.AllergyNotesHeader {
  font-size: 16px;
  font-weight: bold;
}

.AllergyNotesText {
  padding-top: 2%;
  font-size: 12px;
  font-weight: normal;
}
