.greyText {
  color: grey;
}

.topSegment {
  border: 0 !important;
  -webkit-box-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
}

.searchInput {
  display: inline-block;
}

.hideNotAvailableCheckbox {
  margin-left: 2vh;
}
