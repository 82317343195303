.mainModal {
  background-color: rgba(0, 0, 0, 0.75); /* Transparent background */
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ariEventBorder {
  border: 2px solid #143ca1;
  border-radius: 16px;
  background-color: #143ca1;
  color: #fff;
  padding: 2rem;
}

.modalContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ariEventTitleBackground {
  background-color: #143ca1;
  padding: 1rem;
  border-radius: 16px;
  margin-bottom: 1.5rem;
}

.ariEventHeaderText {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
}

.topRightIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.pointerIcon {
  cursor: pointer;
}

.ariEventTextContainer {
  text-align: center;
  margin-bottom: 1.5rem;
}

.ariEventText {
  font-size: 1.5rem;
  color: #ffffff;
}

.ariEventErrorTextContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.ariEventErrorText {
  color: red;
  font-weight: bold;
}

.buttonsRow {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.button {
  width: 8rem;
  height: 4rem;
  font-size: 1.5rem;
  border-radius: 16px;
}

.acceptButton {
  background-color: #21ba45;
  color: #ffffff;
}

.declineButton {
  background-color: #db2828;
  color: #ffffff;
}
