.MenuItem,
.MenuItemNew,
.MenuItemInProgress,
.MenuItemReady,
.MenuItemCancelled {
  margin: 5px 10px;
  user-select: none;
  border: 1px darkgray solid;
  width: 38vh;
  border-radius: 5px;
  white-space: normal;
  box-shadow: 2px 2px rgb(240, 239, 239);
}

.MenuItemNew {
  border-left: 4px #D14944 solid;
}

.MenuItemInProgress {
  border-left: 4px #E1972E solid;
}

.MenuItemReady {
  border-left: 4px #23AD96 solid;
}

.MenuItemCancelled {
  border-left: 4px #B5BCC5 solid;
}

.MenuItemContainer,
.MenuItemContainerFlashing {
  position: relative;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.MenuItemContainerFlashing {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: #D14944;
  }
}

.DetailsContainer {
  color: black;
}

.DetailsContainerTop,
.DetailsContainerTopNew,
.DetailsContainerTopInProgress,
.DetailsContainerTopReady,
.DetailsContainerTopCancelled{
  color: white;
  padding-bottom: 2%;
  padding-top: 3%;
  text-align: center;
}

.DetailsContainerTopNew {
  background-color: #D14944;
}

.DetailsContainerTopInProgress {
  background-color: #E1972E;
}

.DetailsContainerTopReady {
  background-color: #23AD96;
}

.DetailsContainerTopCancelled {
  background-color: #B5BCC5;
}

.OrderHeader {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}

.OrderHeaderText {
  font-size: 22px;
  font-weight: bold;
}

.OrderInformation {
  margin-bottom: 6%;
  margin-top: 4%;
}

.ChangeStatus {
  margin-bottom: 12px;
  margin-top: 8%;
  text-align: center;
}

.OrderNumber {
  font-weight: bold;
  font-size: 36px;
}

.OrderTime {
  font-size: 20px;
}

.CancelButtonContainer {
  display: flex;
  justify-content: center;
}

.CancelButton {
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  justify-content: center;
  letter-spacing: 0.13125px;
  text-transform: uppercase;
  color: #DA291C;
  padding-bottom: 5%;
  background:none;
  border:none;
  margin:0;
}

.CancelButton:hover{
  cursor: pointer;
}

.DetailsList {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  overflow-wrap: break-word;
}

.DetailsListItem {
  font-size: 16px;
  font-weight: bold;
}

.DetailsListItemDescription {
  font-size: 12px;
  font-weight: normal;
}

.DetailsListSubItemDescription{
  font-size: 12px;
  font-weight: bolder;
}

.DetailsListItemDescriptionStriked {
  font-size: 12px;
  font-weight: normal;
  text-decoration: line-through;
}

.Summary {
  padding: 5%;
}

.SegmentTitleParagraph {
  margin-bottom: 1px;
  color: dimgrey;
}

.SegmentContentParagraph {
  font-size: 19px;
  font-weight: bold;
}

.SegmentContentParagraphSmall {
  font-size: 15px;
  font-weight: bold;
}


.PointerIcon:hover {
  cursor: pointer;
}

.AllergyNotes {
  background: #FFC9C9;
  border-top: 1px #E0E0E0 solid;
  border-bottom: 1px #E0E0E0 solid;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.AllergyNotesHeader {
  font-size: 16px;
  font-weight: bold;
}

.AllergyNotesText {
  padding-top: 2%;
  font-size: 12px;
  font-weight: normal;
}
