.orderLabel {
  font-size: 6.0vh !important;
}

/* Apply animation to the element */
.fadeIn {
  opacity: 0; /* Ensure the element is initially hidden */
  transition: opacity 2s;
}

.visible {
  opacity: 1;
}

.animationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: normal;
}

.animationItem {
  font-weight:bold;
  animation-name: vibrate-fast;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
  animation: vibrate-fast 1s linear 0s 1 normal none;*/
}

@keyframes vibrate-fast {

  0% {
    transform:translate(0);
  }
  10% {
    transform:translate(-2px,-2px);
  }
  20% {
    transform:translate(2px,-2px);
  }
  30% {
    transform:translate(-2px,2px);
  }
  40% {
    transform:translate(2px,2px);
  }
  50% {
    transform:translate(-2px,-2px);
  }
  60% {
    transform:translate(2px,-2px);
  }
  70% {
    transform:translate(-2px,2px);
  }
  80% {
    transform:translate(-2px,-2px);
  }
  90% {
    transform:translate(2px,-2px);
  }
  100% {
    transform:translate(0);
  }
}
