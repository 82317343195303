.orderTypeHeader {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-weight: bold;
}

.readyHeader {
  line-height: normal;
  color: #f8f8f8;
}

.waitingHeader {
  line-height: normal;
  color: #00000;
}

.oneSideContainer {
  padding: 1em;
}

.twoColumnNumberList {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
}

.twoColumnNumberList div {
  margin-bottom: 5px;
  text-align: center;
}

