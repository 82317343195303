.DetailsContainer {
  margin-left: 10%;
  margin-right: 10%;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 2px 2px rgb(20, 48, 19);
}

.DetailsContainerIcon {
  float: right;
  margin-right: -3%;
  margin-top: -3%;
}

.ErrorHeader {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  padding-bottom: 5%;
  margin-bottom: 4%;
  margin-top: 4%;
}

.ErrorHeaderText {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.ErrorMessage {
  font-size: 18px;
}

.PointerIcon:hover {
  cursor: pointer;
}

.BorderError {
  border-left: 4px #D14944 solid;
}

.BackgroundError {
  background-color: #D14944;
}
