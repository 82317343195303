.failure {
    background-color: #B00020;
    color: white;
}

.success {
    background-color: #388E3C;
    color: white;
}

.menu-item-wrapper > div > div > div {
    overflow-y: auto;
    overflow-x: none;
}
