.container {
  display: flex;
  flex-direction: row;
}
.qmsControlContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.column {
  flex: 1;
  padding: 10px;
}

.columnFlex2 {
  flex: 2;
  padding: 10px;
}

.inputTextColor input:disabled  {
  opacity: 1 !important;
  color: black !important;
  font-weight: bold !important;
}

.linkMessage {
  color: darkgrey;
  font-weight: bold;
  font-size: 2.0vh !important;
}

.qmsInstructionContainer {
  padding-top: 40px;
  color: darkgrey;
  font-size: 1.8vh !important;
}

.qmsInstructionTitle {
  text-decoration: underline;
}

.qmsInstructionBold {
  font-weight: bold;
}

.qmsInstructionList {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Optional: Remove default padding */
}

.qmsInstructionList li {
  position: relative;
  padding-left: 1.5em; /* Space for the dash */
}

.qmsInstructionList li::before {
  content: "-"; /* Insert dash before each item */
  position: absolute;
  left: 0;
}
